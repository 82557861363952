* {
  margin: 0;
  padding: 0;
  background-color:#1a1a2d;
  background-size: cover;
  background-position: center;
  font-family: 'Rubik', sans-serif;
  --main-content-color: #e7e6e6;
  --secondary-content-color: #d3d3d3;
  --tertiary-content-color: #FB7409;
  scroll-behavior: smooth;

}
.onload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: auto;
  margin-left: 10%;
  margin-right: 10%;
  gap: 7px;
}
#name {
  color: var(--main-content-color);
  max-height: 50px;
  max-width: 320px;
  font-size: 40px;
  white-space: nowrap;
  border-right: .17em solid #FB7409;
  overflow: hidden;
  letter-spacing: .1em;
  animation: 
  typing 4s steps(45, end),
  blink-caret 0.75s step-end infinite;
}
@keyframes typing {
  from { width: 0}
  to { width: 100%; }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  25% { border-color: #FB7409; }
}
@media (max-width: 500px) {
  #name {
  animation: 
  typing 1.25s steps(13, end),
  blink-caret 0.75s step-end infinite;
  }
}
#info {
  max-height: 40px;
  max-width: 440px;
  color: var(--secondary-content-color)
}
#info-block {
  max-height: 35px;
  position: absolute;
  border: solid transparent;
  animation-name: block-reveal;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  margin-top: -33px;
  margin-left: -5px;
  background-color: #FB7409;
  max-width: 248px;
  border: transparent;
  height: 100%;
}
@media (max-width: 500px) {
  #info-block {
    animation-name: block-reveal;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-direction: reverse;
  }
}
@keyframes block-reveal {
  100% {width: 85%;}
  0% {width: 0;}
} 
.scrollIcon {
  position: absolute;
  height: auto;
  width: 50px;
  animation-name: scrollMove;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 2.25s;
  animation-direction: alternate;
  opacity: 0;
  margin-top: 200px;
}
@keyframes scrollMove {
  0% { opacity: 0; margin-top: 200px;}
  100% { opacity: 1; margin-top: 210px;}
}
#scroll-down-wrapper {
  display: flex;
  justify-content: center;
}
@media (min-width: 1200px) {
  .fadeAnimation {
    animation: fade-in 0.5s 2s 1 forwards;
    pointer-events: auto;
  }
}
.bookMark-wrapper {
  position: fixed;
  float: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: auto;
  top: 10px;
  width: 5%;
  margin-top: 100px; 
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .bookMark-wrapper {
   margin-left: 105.5%;
  }
}
@media (min-width: 1300px) {
  .bookMark-wrapper {
   margin-left: 102.5%;
  }
}
@media (min-width: 1400px) {
  .bookMark-wrapper {
   margin-left: 101.5%;
  }
}
@media (min-width: 1500px) {
  .bookMark-wrapper {
   margin-left: 101%;
  }
}
@media (min-width: 1700px) {
  .bookMark-wrapper {
   margin-left: 100%;
  }
}
@media (min-width: 2000px) {
  .bookMark-wrapper {
   margin-left: 99.6%;
  }
}
@media (min-width: 2200px) {
  .bookMark-wrapper {
   margin-left: 98.6%;
  }
}
#bookmark1:hover, #bookmark2:hover, #bookmark3:hover, #bookmark4:hover, #bookmark5:hover {
  margin-right: 60%;
}
@media (max-width: 900px) {
  .bookMark-wrapper {opacity:0; height: 0px; width: 0px;}
}
@media (min-width: 1200px) {
  .fadeAnimation {
    animation: fade-in 0.5s 3s 1 forwards;
    pointer-events: auto;
  }
}
@keyframes fade-in {
  from {opacity: 0; }
  to {opacity: 1;}
}
.bookMark {
  height: 85px;
  margin-top: -34px;
  width: auto;
  display: flex;
  background-color: transparent;
}
#enter-bookmark {
  position: absolute;
    margin-top: -200px;
    width: 1px;
    height: auto;
}
#about-me-wrapper {
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 10%;
  margin-right: 10%;
  gap: 5px;
}
#about-me-page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 2%;
  color: var(--main-content-color);
  padding: 15px;
}
#fade-right-about-me {
  margin-right: 10px;
  opacity: 1;
}
.animationRight {
  position: absolute;
  animation-name: fadeRight;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: transparent;
}
@keyframes fadeRight {
  from {margin-right: 600px; opacity: 0;}
  to {margin-right: 175px; opacity: 1;}
}
#fade-left-about-me {
  margin-left: 10px;
  opacity: 1;
}
.animationLeft {
  position: absolute;
  animation-name: fadeLeft;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: transparent;
}
@keyframes fadeLeft {
  from {margin-left: 600px; opacity: 0;}
  to {margin-left: 175px; opacity: 1;}
}
#grid-container {
  display: flex;
  justify-content: center;
  object-fit: contain;
  max-width: 1500px;
  margin-bottom: 5%;
}
.about-me-content-wrapper {
  display: grid;
  grid-template-rows: 100px 100px 100px;
  grid-template-columns: 200px 200px 200px 200px;
  min-height: 30vh;
  max-width: 100%;
  gap: 15px;
  color: var(--secondary-content-color);
}
@media (max-width: 900px) {
  .about-me-content-wrapper {grid-template-columns: repeat(3, 100px);}
}
.about-me-text-content {
  display: flex;
  color: var(--secondary-content-color);
  text-align: center;
}
#about-me-text-content-animation, #about-me-text-content-animation2, #about-me-text-content-animation3 {
  animation-name: reveal-ease-in;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes reveal-ease-in {
  from {opacity:0;}
  to {opacity: 1;}
}
#about-me-tech-stack-title {
  color: var(--main-content-color);
  padding: 15px;
}
.grid-square {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  perspective: 1000px;
}
.icon {
  max-height: 60px;
  min-height: 10px;
}
#icon1, #icon5, #icon9 {
  animation-name: flipper;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes flipper {
  from {transform:rotateY(90deg);
        opacity:0;}
  to {transform:rotateY(0deg);
        opacity:1;}
}
#icon2, #icon6, #icon10 {
  animation-name: flipper;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0.15s;
  animation-fill-mode: forwards;
  opacity: 0;
}
#icon3, #icon7, #icon11 {
  animation-name: flipper;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0.30s;
  animation-fill-mode: forwards;
  opacity: 0;
}
#icon4, #icon8, #icon12 {
  animation-name: flipper;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0.45s;
  animation-fill-mode: forwards;
  opacity: 0;
}
#projects-wrapper {
  min-height: 100vh;
  margin-left: 7%;
  margin-right: 7%;
  max-width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#projects-page-title {
  color: var(--main-content-color);
}
.projects-grid-wrapper {
  display: grid;
  margin-bottom: 5%;
  grid-template-columns: 650px 650px;
  grid-template-rows: repeat(4 310px);
  background-color: transparent;
  gap: -1px;
  object-fit: cover;
} 
@media (max-width: 1200px) {
  .projects-grid-wrapper {grid-template-columns: 600px} 
}
@media (max-width: 900px) {
  .projects-grid-wrapper {grid-template-columns: 375px} 
}
.projects-thumbnail-image {
 object-fit: cover;
 max-width: 700px;
 max-height: 338px;
 width: 100%;
 height: 100%;
}
.project-info-card-button-wrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  gap: 40px;
  margin-top: 650px;
  opacity: 0;
  background-color: transparent;
}
.live-code-project-grid-buttons {
  background-color: transparent;
  border: 1px solid #FB7409;
  color: white;
  border-radius: 5px;
  font-size: 17px;
  padding: 15px;
  width: 150px;
  opacity: 1;
}
.live-code-project-grid-buttons:hover {
  opacity: 0.8;
  animation-name: button-press;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes button-press {
  from {transform:rotateX(0deg); background-color: transparent;}
  to {transform:rotateX(10deg); background-color: #FB7409;}

}
#project-grid-square-animation1 {
  animation-name: button-reveal;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 1;
  opacity: 0;
}
#fadeClass {
  animation-name: hoverFade;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes hoverFade {
  from {opacity: 1;}
  to {opacity: 0.1;}
}
#project-grid-square-animation-initial {
  position: absolute;
  margin-top: 600px;
}
@keyframes button-reveal {
  from {margin-top: 600px; opacity: 0;}
  to {margin-top: 500px; opacity: 1;}
}
@media  (max-width: 900px) { 
  #project-grid-square-animation-initial {margin-top: 300px;}
  .project-title{margin-top: -35px;}
  .project-subheading{margin-top: -10px;}
  @keyframes button-reveal {
    from {margin-top: 300px; opacity: 0;}
    to {margin-top: 280px; opacity: 1;}
  }
}
.project-title-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  background-color: transparent;
}
.project-title-card-header {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  background: inherit;
  text-align: center;
  margin-top: 60px;
  z-index: 1;
  gap: 10px;
  opacity: 0;
}
.project-title {
  background-color: transparent;
  color:  var(--main-content-color);
  font-weight: bold;
}
.project-subheading {
  color: var(--tertiary-content-color);
  font-size: 20px;
}
#header-reveal-animation-class {
  animation-name: header-reveal;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes header-reveal {
  from {margin-top: 60px; opacity: 0;}
  to {margin-top: 150px; opacity: 1;}
}
.contact-me-page-wrapper {
  height: 80vh;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#contact-me-page-title {
  margin-top: -10px;
  color: var(--main-content-color);
}
#contact-me-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-height: 100vh;
  max-width: 100%;
  color: white;
}
.contact-me-form-inputs {
  border: solid var(--tertiary-content-color) 1px;
  color: var(--main-content-color);
  border-radius: 5px;
  height: 40px;
  width: 600px;
  padding: 3px;
  max-width: 700px;
  min-width: 292px;
  font-size: 20px;
  color: white;
}
.contact-me-form-inputs:focus {
  outline: white;
  border-color: var(--main-content-color);
}
#conact-me-content-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 70px;
  width: 100%;
}
@media (max-width: 1100px) {
  #conact-me-content-wrapper{flex-direction: column; margin-top: 25px}
  #contact-me-page-title{margin-top: 200px;}
  .contact-me-form-inputs { width: 100%;}
  #page-bottom {margin-bottom: 10px;}
  #send-icon-text { width: 200px; overflow-wrap: break-word; }
  #contact-me-links-wrapper {margin-top: -20px;}
  #conact-me-content-wrapper {gap: 0px;}
  #contact-me-submit-button {max-width: 320px; min-width: 300px;}
}
.contact-me-shape {
  height: 3px;
  width: 100%;
  background-color: #FB7409;
}
.send-Icon {
  margin-top: 0px;
  height: 40px;
  margin-left: 0px;
  width: auto;
  background-color: transparent;
}
.send-email-animation {
  animation-name: send-animation;
  animation-duration: 0.75s;
  /* animation-fill-mode: forwards; */
  /* animation-iteration-count:; */
}
@keyframes send-animation {
  from {margin-left: 0px; opacity: 1;}
  to {margin-left: 300px; opacity: 0;}
} 
.link-Icon {
  margin-top: 5px;
  height: 40px;
  width: auto;
}
#contact-me-links-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

#contact-me-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border: solid  var(--tertiary-content-color) 1px;
  border-radius: 5px;
}
#contact-me-submit-button:hover {
  animation-name: button-press;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
#contact-me-message-input {
  height: 150px;
  resize: none;
}
.contact-me-links {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-content-color);
  gap: 40px;
}
#contact-me-social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  width: 100%;
}
.footer-wrapper {
width: 100%;
max-height: 6vh;
border: solid;
border-color: var(--tertiary-content-color);
display: flex;
align-items: center;
justify-content: center;
padding: 5px;
color: var(--secondary-content-color);
font-weight: bold;

}
.made-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

}
.content-wrapper-footer {
  padding-top: 5px;
  
}

#github-logo {
  height: 40px;
  width: auto;
}
@media (max-width: 1000px) {
  .footer-wrapper {
    opacity: 0;
  }
}
.section-heading {
  padding: 25px;
}
.professional-work-title-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
  background-color: transparent;
  margin-bottom: 50px;
}
.professional-title-card-header {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  background: inherit;
  text-align: center;
  margin-top: 0px;
  z-index: 1;
  gap: 10px;
  opacity: 0;
}
.professional-work-thumbnail-image {
  object-fit: cover;
  max-width: 1000px;
  max-height: 500px;
  width: 100%;
  height: 100%;
 }
 #professional-animation-initial {
  position: absolute;
  margin-top: 600px;
 }
 #professional-animation1 {
  animation-name: professional-button-reveal;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 1;
  opacity: 0;
}
@keyframes professional-button-reveal {
  from {margin-top: 0px; opacity: 0;}
  to {margin-top: 250px; opacity: 1;}
}
@media  (max-width: 900px) { 
  #professional-animation-initial {margin-top: 300px;}
  .project-title{margin-top: -35px;}
  .project-subheading{margin-top: -10px;}
  @keyframes professional-button-reveal {
    from {margin-top: 0px; opacity: 0;}
    to {margin-top: 150px; opacity: 1;}
  }
}
#professional-reveal-animation-class {
  animation-name: professional-header-reveal;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes professional-header-reveal {
  from {margin-top: -150px; opacity: 0;}
  to {margin-top: 0px; opacity: 1;}
}
.professional-work-link {
  background-color: transparent;
    border: 1px solid #FB7409;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    padding: 15px;
    width: 200px;
    opacity: 1;
}
.professional-work-link:hover {
  opacity: 0.8;
  animation-name: button-press;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}